<template>
    <v-container fluid class="pt-0 mr-10 ml-10">        
      <v-row>
            <v-col xl="10" lg="10" cols="10" >
              <p class="page-title">{{$t('errors.UNDER_CONSTRUCTION')}}</p>
              <p class="page-subtitle">{{$t('errors.UNDER_CONSTRUCTION')}}</p>
            </v-col>
        </v-row>
        <v-layout row wrap>
            <v-col>
                <InstructionsFilters @change-guide="changeGuide"    
                    class="mt-3"/>
            </v-col>
            <v-col cols="9" class="mr-12">
                <!-- Page view -->
                <router-view :key="reloadPage" :guidePage="selected"></router-view>
                <!-- end -->
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    name: 'InstructionsGuide',
    data() {
      return {
        selected:'',
        reloadPage: 0
      }
    },
    methods:{
        changeGuide(place){
            this.selected = place
        }
    }
  }
</script>